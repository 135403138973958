import { Wrapper } from "./style";
import Loading from "react-loading-icons";

interface RectangularButton {
  outline?: boolean;
  text: string;
  isLoading?: boolean;
  onClick?: any;
}

export const RectangularButton = ({
  outline,
  text,
  isLoading,
  onClick,
}: RectangularButton) => {
  return (
    <Wrapper onClick={onClick}>
      {isLoading ? <Loading.Oval width={30} /> : text}
    </Wrapper>
  );
};
