import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Login } from "../../pages/Login";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import FirstConsultation from "../../pages/FirstConsultation";

export const PublicRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}>
            <Route
              path="*"
              element={isAuthenticated == false && <Navigate to="/" />}
            />
          </Route>
          <Route
            path="/firstConsultation/:token"
            element={<FirstConsultation />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};
