import styled from "styled-components";

export const PageWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`

export const TopWrapper = styled.div`
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 15px;
`

export const FiltersWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border: 1px solid #919EAB33;
    padding: 1px 1px 4px 1px;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
`

export const DayResumeWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;
`

interface ListWrapperProps {
    emptyList?: boolean
}

export const ListWrapper = styled.div<ListWrapperProps>`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 0 10px 10px 10px;
    gap: 16px;
    justify-content: ${props => props.emptyList ? 'center' : 'stretch'};
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    overflow-y: auto;
    overflow-x: hidden;
    ${props => props.emptyList ? 'align-items: center;' : ''}
`

export const RightMenuWrapper = styled.div`
    /* width: 18%;
    height: 100%;
    overflow: auto;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0,0,0,0.1); */
`

export const ClockWrapper = styled.div`
    display: flex;
    width: '100%';
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
`

export const ClockImageWrapper = styled.div`
    margin-top: 10px;
`

export const ClockHour = styled.div`
    font-size: 35px;
    font-weight: 300;
    font-family: Jost;
`

export const ClockInfoDay = styled.div`
    font-size: 11px;
`

export const ShortcutWrapper = styled.div`
    padding: 20px;
`

export const ShortcutClickable = styled.div`
    width: 112px;
    height: 84px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: background-color 0.3s ease; 

    &:hover {
        background-color: #E8E8E8;
    }
`

export const ShortCut = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    gap: 15px 0;
`

export const InfoWrapper = styled.div`
    padding: 20px;
`

export const InfoContainer = styled.div`
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: 0.2px solid #d3d3d3;
    border-radius: 10px;
    width: 100px;
    height: 60px;
    display: flex;
    flex-direction: column;
`

export const InfoHeader = styled.span`
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 3px;
`

export const Info = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    gap: 15px 0;
`

export const ListEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ListEmptyLabel = styled.div`
    margin-top: 5px;
    color: #ccc;
`