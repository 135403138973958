import { ReactNode } from "react";
import { ContentWrapper, PageWrapper, RightContentWrapper } from "./style";
import SideMenu from "../SideMenu";

interface PageStructureProps {
  title?: string;
  children: ReactNode;
  rightContent?: ReactNode;
  hidesidebar?: boolean;
  overflow?: string;
}

export const PageStructure = ({
  title,
  children,
  rightContent,
  hidesidebar,
  overflow
}: PageStructureProps) => {
  return (
    <PageWrapper hidesidebar={hidesidebar}>
      {!(hidesidebar) && <SideMenu />}
      <ContentWrapper hidesidebar={hidesidebar} style={{overflow}}>
        {title && <h1>{title}</h1>}
        {children}
      </ContentWrapper>
      {rightContent && (
        <RightContentWrapper>{rightContent}</RightContentWrapper>
      )}
    </PageWrapper>
  );
};

export default PageStructure;
