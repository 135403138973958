import { useEffect, useRef, useState } from "react";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GrValidate } from "react-icons/gr";
import { IoMdPersonAdd } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineFilterList } from "react-icons/md";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { animated, useSpring, useTrail } from "react-spring";
import { toast } from "react-toastify";
import Logo from "../../assets/check.png";
import CustomDropdown from "../../components/CustomDropDown";
import PageStructure from "../../components/PageStructure";
import { customStyles, customStylesPaciente } from "../../components/Shared";
import { LogoWrapper } from "../../components/SideMenu/style";
import CreateProfessional from "../../modals/CreateProfessional";
import { CreateTransactionHistory } from "../../modals/CreateTransactionHistory";
import CreateEvent from "../../modals/Event/Create";
import { HeaderTitle, HeaderWrapper } from "../../modals/Event/Create/style";
import CreateUser from "../../modals/Patient/Create";
import ShowEvent from "../../modals/Show";
import api from "../../service/api";
import { getProfessionalId } from "../../service/token.service";
import formatCurrency from "../../utils/formatCurrencyBr";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import { getFirstAndLastDayOfMonth } from "../../utils/getFirstAndLastDayOfMonth";
import { getFirstAndLastDayOfWeek } from "../../utils/getFirstAndLastDayOfWeek";
import { groupTransactionsByDate } from "../../utils/groupTransactionsByDate";
import PatientProfile from "../Patients/Profile";
import { ProfessionalInfos } from "./Components/ProfessionalInfos";
import { FiltersWrapper, ListEmptyLabel, ListEmptyWrapper, ListWrapper, MainWrapper, PageWrapper } from "./style";

const shortCuts = [
  {
    name: 'Criar profissional',
    route: '/',
    icon: <IoMdPersonAdd size={20} color="var(--primary-icon-color)" />
  },
  {
    name: 'Criar compromisso',
    route: '/',
    icon: <GrValidate size={20} color="var(--primary-icon-color)" />
  },
  {
    name: 'Criar paciente',
    route: '/',
    icon: <IoMdPersonAdd size={20} color="var(--primary-icon-color)" />
  },
  {
    name: 'Movimentacao',
    route: '/',
    icon: <FaMoneyBillTransfer size={20} color="var(--primary-icon-color)" />
  }
]

interface InfoToShow {
  info: string,
  value: any,
  fontColor?: string;
}

interface Transaction {
  id: number;
  title: string;
  type: string;
  description: string;
  amountValue: string;
  date: string;
}

export const Today = () => {
  let time = new Date();
  const navigate = useNavigate();
  const [ctime, setTime] = useState(time);
  const [infoToShow, setInfoToShow] = useState([] as InfoToShow[]);
  const [appointments, setAppointments] = useState([] as any[]);
  const trailSprings = useTrail(appointments.length, {
    from: { transform: "translateX(500px)", opacity: 0, background: "#fff" },
    to: { transform: "translateX(0px)", opacity: 1, background: "#FEFEFF" },
  });
  const [modaDetaillIsOpen, setDetailIsOpen] = useState(false);
  const defaultTab = useRef('Compromisso')
  const [appointmentClicked, setAppointmentClicked] = useState({} as any);
  const [modalPersonDetail, setModalPersonDetail] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalRegisterProfessionalOpen, setModalRegisterProfessionalOpen] = useState(false);
  const [modalRegisterAppointmentOpen, setModalRegisterAppointmentOpen] = useState(false);
  const [modalCreatePatientOpen, setModalCreatePatientOpen] = useState(false);
  const [modalCreateEntryOpen, setModalCreateEntryOpen] = useState(false);
  const [professionals, setProfessionals] = useState<any>([]);
  const [selectedProfessional, setSelectedProfessional] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState<any>([]);
  const [prontoLoading, setProntoLoading] = useState(false);

  const [transactionsByDate, setTransactionsByDate] = useState<{
    [date: string]: Transaction[];
  }>({});
  const [receiveValue, setReceiveValue] = useState("");
  const [outValue, setOutValue] = useState("");
  const [pendencyValue, setPendencyValue] = useState("");
  const [weeklyChartData, setWeeklyChartData] = useState<any[]>([]);
  const [monthChartData, setMonthChartData] = useState<any[]>([]);
  const [sortChart, setSortChart] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const refreshParam = searchParams.get('refresh');

    if (refreshParam && refreshParam == 'true') {
      getAppointments();

      searchParams.delete("refresh");

      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      }, { replace: true });
    }
  }, [searchParams])

  useEffect(() => {
    getAppointments();
    const interval = setInterval(UpdateTime, 1000);

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if (openModal) {
      if (defaultTab.current == 'Compromisso') setDetailIsOpen(true)
      else if (defaultTab.current == 'Pagamento') setDetailIsOpen(true)
      else if (defaultTab.current == 'Paciente') showPersonModal(appointmentClicked.extendedProps.patient)

      setOpenModal(false)
    }
  }, [openModal])

  const handleOpenModal = (name: string) => {
    switch (name) {
      case 'Criar profissional':
        setModalRegisterProfessionalOpen(true)
        break;
      case 'Criar compromisso':
        setModalRegisterAppointmentOpen(true)
        break;
      case 'Criar paciente':
        setModalCreatePatientOpen(true)
        break;
      case 'Movimentacao':
        setModalCreateEntryOpen(true)
        break;
    }
  }

  const showPersonModal = (patient: any) => {
    navigate('', { state: { patient: patient, hidesidebar: true } })
    setModalPersonDetail(true)
  }

  const getAppointments = async () => {
    const ctoday = new Date().toISOString().split('T')[0];
    const appointments = await api
      .get(
        `/appointments/?start_date=${ctoday}&end_date=${ctoday}${getProfessionalId() ? '&professional_id=' + getProfessionalId() : ''}`
      )
      .then((response) => {
        const appointments = response.data;
        const events = appointments?.map((appointment: any) => {
          const title = appointment.service;
          return {
            id: appointment.id,
            title,
            start: appointment.start_time,
            end: appointment.end_time,
            allDay: false,
            paciente: appointment.patient.name,
            professional: appointment.professional,
            service: appointment.service,
            establishment: appointment.establishment,
            observation: appointment.observation,
            patient: appointment.patient,
            preform: appointment?.preform,
            status: appointment.status,
            exams: appointment.exams,
          };
        });

        return events;
      })
      .catch(() => {
        toast.error('Erro ao carregar os dados dos agendamentos!')
      });

    const dataAtual = new Date();
    const diaAtual = dataAtual.getDate();
    const mesAtual = dataAtual.getMonth() + 1;
    const anoAtual = dataAtual.getFullYear();

    const eventosFiltrados = appointments?.filter((evento: any) => {
      const start = new Date(evento.start);
      return start.getDate() === diaAtual && start.getMonth() + 1 === mesAtual && start.getFullYear() === anoAtual && evento.status.name != 'Cancelado';
    });

    const tempoTotal = eventosFiltrados.reduce((acumulador: any, appointment: any) => {
      const diferenca = calcularDiferenca(appointment);
      return acumulador + diferenca;
    }, 0);

    const time = converterTempo(tempoTotal);

    const totalPaidValue = eventosFiltrados.reduce((sum: number, appointment: any) => {
      if (appointment?.status.name != "Pago") {
        return sum + 0;
      }

      return sum + appointment.service.price;
    }, 0);

    const totalPendingValue = eventosFiltrados.reduce((sum: number, appointment: any) => {
      if (appointment?.status.name != "Pago") {
        return sum + appointment.service.price;
      }

      return sum + 0;
    }, 0);

    setInfoToShow([
      {
        info: 'Consultas totais',
        value: eventosFiltrados.length
      },
      {
        info: 'Horas ocupadas',
        value: `${time.horas}H ${time.minutos}M`
      },
      {
        info: 'Valor total acumulado',
        value: formatCurrency((totalPaidValue * 100).toString()),
        fontColor: '#118D57'
      },
      {
        info: 'Valor total pendente',
        value: formatCurrency((totalPendingValue * 100).toString()),
        fontColor: '#E67E22'
      },
      {
        info: 'Valor total',
        value: formatCurrency(((totalPaidValue + totalPendingValue) * 100).toString()),
      }
    ])
    const sortedAppointments = [...eventosFiltrados].sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    setAppointments(sortedAppointments);
    setFilteredAppointments(sortedAppointments);
  }

  const UpdateTime = () => {
    time = new Date()
    setTime(time)
  }

  useEffect(() => {
    const uniqueProfessionals = Array.from(new Set(appointments.map(evento => evento.professional.name)));
    uniqueProfessionals.unshift('Todos');
    setProfessionals(uniqueProfessionals);
  }, [appointments]);

  useEffect(() => {
    if (selectedProfessional !== 'Todos' && selectedProfessional) {
      const filtered = appointments.filter(evento => evento.professional.name === selectedProfessional);
      setFilteredAppointments(filtered);
    } else {
      setFilteredAppointments(appointments);
    }
  }, [selectedProfessional, appointments]);

  function calcularDiferenca(objeto: any) {
    const start = new Date(objeto.start) as any;
    const end = new Date(objeto.end) as any;
    const diferenca = end - start;
    return diferenca / 1000;
  }

  function converterTempo(segundos: any) {
    const horas = Math.floor(segundos / 3600);
    const minutos = Math.floor((segundos % 3600) / 60);
    const segundosRestantes = segundos % 60;
    return { horas, minutos, segundos: segundosRestantes };
  }

  const closeModal = () => {
    setDetailIsOpen(false);
    setModalPersonDetail(false);
    setModalRegisterProfessionalOpen(false);
    setModalRegisterAppointmentOpen(false)
    setModalCreatePatientOpen(false);
    setModalCreateEntryOpen(false);
    defaultTab.current = 'Compromisso'
  };

  const fadeDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modaDetaillIsOpen ? 1 : 0 },
  });

  const handleAppointmentClicked = (appointment: any) => {
    const transformedObject = {
      allDay: appointment.allDay,
      title: appointment.title,
      start: appointment.start,
      end: appointment.end,
      id: appointment.id,
      extendedProps: {
        paciente: appointment.paciente,
        professional: appointment.professional,
        service: appointment.service,
        establishment: appointment.establishment,
        observation: appointment.observation,
        patient: appointment.patient,
        preform: appointment?.preform,
        status: appointment.status
      }
    };

    setAppointmentClicked(transformedObject)
    setOpenModal(true)
  }

  const fadePersonDetail = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalPersonDetail ? 1 : 0 },
  });

  const fadeRegisterProfessionalOpen = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalRegisterProfessionalOpen ? 1 : 0 },
  });

  const fadeAppointment = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalRegisterAppointmentOpen ? 1 : 0 },
  });

  const fadeCreatePatient = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalCreatePatientOpen ? 1 : 0 },
  });

  const fetchHistory = () => {
    api.get("/payments/").then((resp) => {
      const mappedReturn = groupTransactionsByDate(resp.data);
      setTransactionsByDate(mappedReturn);
    });
  };

  const fetchWeeklyData = () => {
    let days = getFirstAndLastDayOfWeek();
    const receiveData: any = {
      total: [],
    };
    const outData: any = {
      total: [],
    };
    const pendData: any = {
      total: [],
    };

    api
      .get(`/dashboard/payments/${days.firstDay}/${days.lastDay}/`)
      .then(async (resp) => {
        setReceiveValue(formatDecimalValues(resp.data.total_in));
        setOutValue(formatDecimalValues(resp.data.total_out));
        setPendencyValue(formatDecimalValues(resp.data.total_pend));

        await resp.data.results?.forEach((dados: any) => {
          receiveData.total.push(dados.in);
          outData.total.push(dados.out);
          pendData.total.push(dados.pend);
        });

        const weeklyChartData = [
          {
            name: "Entradas",
            data: receiveData.total,
          },
          {
            name: "Saidas",
            data: outData.total,
          },
          {
            name: "Pendencias",
            data: pendData.total,
          },
        ];

        setWeeklyChartData(weeklyChartData);
        setSortChart("semanal");
      });
  };

  const fetchMonthData = () => {
    let days = getFirstAndLastDayOfMonth();
    const receiveData: any = {
      total: [],
    };
    const outData: any = {
      total: [],
    };
    const pendData: any = {
      total: [],
    };
    api
      .get(`/dashboard/payments/${days.firstDay}/${days.lastDay}/`)
      .then(async (resp) => {
        setReceiveValue(formatDecimalValues(resp.data.total_in));
        setOutValue(formatDecimalValues(resp.data.total_out));
        setPendencyValue(formatDecimalValues(resp.data.total_pend));
        await resp.data.results?.forEach((dados: any) => {
          receiveData.total.push(dados.in);
          outData.total.push(dados.out);
          pendData.total.push(dados.pend);
        });

        const monthChartData = [
          {
            name: "Entradas",
            data: receiveData.total,
          },
          {
            name: "Saidas",
            data: outData.total,
          },
          {
            name: "Pendencias",
            data: pendData.total,
          },
        ];

        setMonthChartData(monthChartData);
      });
  };

  const prontoClicked = (appointment: any) => {
    setProntoLoading(true);
    api.post(`/notificacoes/`, {
      title: `${appointment.patient.name}`,
      message: 'Está pronto para consulta',
      icon: 'https://clinicabridges.com.br/favicon.ico',
      appointment: appointment.id,
    }).then(() => {
      toast.success("Notificação enviada com sucesso!");

      api.patch(`/appointments/${appointment.id}/`, {
        appointment_status: 5
      }).then(() => {
        getAppointments();
      }).catch(() => {
        toast.error("Erro ao atualizar status do compromisso!");
      });
    })
    .catch(() => {
        toast.error("Erro ao enviar notificação!");
    }).finally(() => {
      setProntoLoading(false);
    })
  }

  return (
    <>
      <Modal
        isOpen={modalCreateEntryOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <CreateTransactionHistory
          closeModal={closeModal}
          updateClients={() => { }}
          fetchFunction={fetchHistory}
          fetchWeeklyFunction={fetchWeeklyData}
          sortChart={sortChart}
        />
      </Modal>
      <Modal
        isOpen={modalCreatePatientOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeCreatePatient}>
          <CreateUser
            closeModal={closeModal}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalRegisterAppointmentOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeAppointment}>
          <CreateEvent closeModal={() => { closeModal(); getAppointments(); }} />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalRegisterProfessionalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeRegisterProfessionalOpen}>
          <CreateProfessional
            closeModal={closeModal}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalPersonDetail}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStylesPaciente}
      >
        <animated.div style={fadePersonDetail}>
          <HeaderWrapper>
            <HeaderTitle>Dados do paciente</HeaderTitle>
            <IoCloseOutline
              size={22}
              onClick={closeModal}
              color="#919EAB"
              cursor={"pointer"}
            />
          </HeaderWrapper>
          <PatientProfile />
        </animated.div>
      </Modal>

      <Modal
        isOpen={modaDetaillIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeDetail}>
          <ShowEvent closeModal={closeModal} event={appointmentClicked} defaultTab={defaultTab.current} />
        </animated.div>
      </Modal>

      <PageStructure>
        <PageWrapper>
          <MainWrapper>
            <FiltersWrapper>
              {/* <div style={{ width: '250px', height: '51px' }}>
                <CustomInput
                  disableBorder
                  placeholder="Pesquise..."
                />
              </div> */}

              <div style={{ display: 'flex' }}>
                <div style={{ width: '300px' }}>
                  <CustomDropdown
                    options={professionals}
                    selectedOption={selectedProfessional}
                    placeHolder="Selecione o Profissional"
                    setSelectedOption={(value) => { setSelectedProfessional(value) }}
                    disableBorder
                    leftIcon={
                      <MdOutlineFilterList size={16} color="var(--primary-icon-color)" />
                    }
                  />
                </div>
                {/* <div style={{ width: '150px' }}>
                  <CustomDropdown
                    options={["Ordem Alfabética", "Valores crescentes", "Valores decrescentes", "Consultas próximas", "Consultas distantes"]}
                    selectedOption={''}
                    disableBorder
                    setSelectedOption={(value) => { }}
                    leftIcon={
                      <MdReorder size={16} color="var(--primary-icon-color)" />
                    }
                  />
                </div> */}
              </div>
            </FiltersWrapper>
            <ListWrapper emptyList={filteredAppointments.length === 0}>
              {
                filteredAppointments.length > 0 ? filteredAppointments.map((appointment: any, index: any) => (
                  <animated.div
                    key={index}
                    style={{
                      // ...spring,
                      height: "245px",
                      width: "450px",
                      marginTop: "10px",
                      borderRadius: '12px',
                      minWidth: '340px',
                      display: 'flex',
                      border: '1px solid #E8E8E8',
                      padding: '15px',
                      boxShadow: `${appointment?.status?.color} 5px 3px 1px -1px`,
                      cursor: 'pointer',
                    }}                  >
                    <ProfessionalInfos
                      ctime={ctime}
                      appointment={filteredAppointments[index]}
                      prontoLoading={prontoLoading}
                      appointmentClicked={() => handleAppointmentClicked(appointment)}
                      prontoClicked={() => prontoClicked(appointment)}
                      paymentStatusClicked={() => {
                        defaultTab.current = 'Pagamento'
                        setOpenModal(true)
                      }}
                      clientClicked={() => {
                        defaultTab.current = 'Paciente'
                        handleAppointmentClicked(appointment)
                      }}
                    />
                  </animated.div>
                ))
                  :
                  <ListEmptyWrapper>
                    <LogoWrapper src={Logo} alt="Logo" />
                    <ListEmptyLabel> Sem compromissos agendados! </ListEmptyLabel>
                  </ListEmptyWrapper>
              }
            </ListWrapper>
          </MainWrapper>

          {/* <RightMenuWrapper>
            <ClockWrapper>
              <ClockImageWrapper>
                <Clock renderMinuteMarks={false} renderHourMarks={true} hourMarksLength={5} renderNumbers={false} value={ctime}/>
              </ClockImageWrapper>
              <ClockHour>
                {ctime.toLocaleString('pt-BR', {hour: '2-digit', minute: '2-digit', second: '2-digit'})}
              </ClockHour>
              <ClockInfoDay>
                {ctime.toLocaleString('pt-BR', {day: '2-digit', weekday: 'long', month: 'long', year: 'numeric'})}
              </ClockInfoDay>
            </ClockWrapper>

            <InfoWrapper>
              <span style={{marginBottom: '8px', fontSize: '12px'}}>Informações para hoje</span>
              
              <Info>
                {
                  infoToShow.map((info, index) => {
                    return (
                      <InfoContainer key={index}> 
                        <InfoHeader>
                          {info.info}
                        </InfoHeader>
                        <SeparatorBottom />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, fontSize: '11px', color: info?.fontColor || "#000"}}>
                          {info.value}
                        </div>
                      </InfoContainer>
                    )
                  })
                }
              </Info>
            </InfoWrapper>

            <ShortcutWrapper>
              <span style={{ fontSize: '12px'}}>Atalhos ({shortCuts.length})</span>
              
              <ShortCut>
                {shortCuts.map((shortcutObject) => {
                  return (
                    <ShortcutClickable
                      onClick={() => {
                        handleOpenModal(shortcutObject.name)
                      }}
                    >
                      {shortcutObject.icon}
                      <span style={{fontSize: '10px', marginTop: '5px'}}>
                        {shortcutObject.name}
                      </span>
                    </ShortcutClickable>
                  )
                })}
              </ShortCut>
            </ShortcutWrapper>
          </RightMenuWrapper> */}
        </PageWrapper>
      </PageStructure>
    </>
  );
};

export default Today;
