import { ReactNode } from "react";
import { MenuItemWrapper, Title } from "./style";

interface MenuButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    leftIcon?: ReactNode;
    title: string;
    isActive: boolean;
}

export const MenuButton = ({leftIcon, title, isActive, ...rest}: MenuButtonProps) => {
    return <MenuItemWrapper isActive={isActive} {...rest}>
        {leftIcon && leftIcon}
        {title && <Title>{title}</Title>}
    </MenuItemWrapper>
}