import styled from "styled-components";

interface MenuItemProps {
    isActive: boolean;
}

export const MenuItemWrapper = styled.div<MenuItemProps>`
    display: flex;
    gap: 5px;
    width: 100%;
    background-color: ${props => props.isActive ? '#d8dcfc' : 'transparent'};
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    transition: .2s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #d8dcfc;
    }
`   

export const Title = styled.span`
    font-size: 16px;    
    font-weight: 400;
`