import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Bgdetail01 from "../../assets/Bgdetail01.png";
import Bgdetail02 from "../../assets/Bgdetail02.png";
import Bgdetail03 from "../../assets/Bgdetail03.png";
import Bgdetail04 from "../../assets/Bgdetail04.png";
import checkLogo from "../../assets/check.png";
import { RectangularButton } from "../../components/RectangularButton";
import { useAuth } from "../../context/AuthContext";
import api from "../../service/api";
import {
  Detail,
  Input,
  InputTitle,
  InputWrapper,
  Title,
  WrapperBottom,
  WrapperHeader,
  WrapperImg,
  WrapperInputIcon,
  WrapperLogin,
  WrapperMid,
  WrapperPage
} from "./style";

export const Login = () => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const { setMail, setPassword, mail, password, setIsAuthenticated } =
    useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleVisiblePassword = () => {
    if (!isVisiblePassword) setIsVisiblePassword(true);

    if (isVisiblePassword) setIsVisiblePassword(false);
  };

  const Login = () => {
    const data = {
      username: mail,
      password: password,
    };
    setIsLoading(true);
    api
      .post("/login/", data)
      .then((resp: any) => {
        localStorage.setItem("bridges.token", resp.data.tokens.access);
        const tokenData = jwtDecode(resp.data.tokens.access) as any;
        if(tokenData.groups[0] == "financeiro")
          window.location.href = "/wallet";
        else
          window.location.href = "/today";
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("E-mail ou senha inválidos");
      });

    setIsAuthenticated(true);
  };

  return (
    <WrapperPage>
      <WrapperHeader>
        <Detail src={Bgdetail01} width={500} />
        <Detail src={Bgdetail02} width={550} height={300} />
      </WrapperHeader>
      <WrapperMid>
        <WrapperLogin>
          <WrapperImg>
            <img src={checkLogo} />
          </WrapperImg>
          <Title>Entrar</Title>
          <InputWrapper>
            <InputTitle>E-mail</InputTitle>
            <Input
              placeholder="Digite seu email"
              onChange={(e) => setMail(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <InputTitle>Senha</InputTitle>
            <WrapperInputIcon>
              <Input
                placeholder="Digite suas senha"
                type={isVisiblePassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
              />
              {isVisiblePassword ? (
                <FiEyeOff
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  color="#212B36"
                  onClick={() => handleVisiblePassword()}
                />
              ) : (
                <FiEye
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  color="#212B36"
                  onClick={() => handleVisiblePassword()}
                />
              )}
            </WrapperInputIcon>
          </InputWrapper>
          {/* <WrapperRecovery>
            <RecoveryLabel>Esqueci minha senha</RecoveryLabel>
          </WrapperRecovery> */}
          <RectangularButton
            text="Entrar"
            onClick={() => Login()}
            isLoading={isLoading}
          />
        </WrapperLogin>
      </WrapperMid>
      <WrapperBottom>
        <Detail src={Bgdetail03} width={500} />
        <Detail src={Bgdetail04} width={500} />
      </WrapperBottom>
    </WrapperPage>
  );
};
