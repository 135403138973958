import styled from 'styled-components';

export const WrapperPage = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: none;
`;

export const WrapperHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 20%;
`;

export const WrapperDetail = styled.div`
`

export const Detail = styled.img`
`;

export const WrapperMid = styled.div`
    display: flex;
    flex: 5;
    align-items: center;
    justify-content: center;
`;

export const WrapperBottom = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    height: 20%;
`;

export const WrapperLogin = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
    position: relative;
    padding: 14px;
    border:1px solid #212B36;
    border-radius: 4px;
`;

export const Input = styled.input`
    width: 100%;
    font-size: 15px;
    outline: none;
    border: none;
`;

export const InputTitle = styled.div`
    position: absolute;
    margin-top: -24px;
    background-color: white;
    margin-left: 5px;
    font-weight: bold;
    font-size: 14px;
`;

export const WrapperInputIcon = styled.div`
    display: flex;
`;

export const InputIcon = styled.div`
    cursor: pointer;
    font-size: 20px;
`;

export const WrapperImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
`;

export const WrapperRecovery = styled.div`
    display: flex;
    justify-content: end;
`;

export const RecoveryLabel = styled.div`
    border-bottom: 1px solid #212B36; 
    color: #212B36;
`;