import styled from "styled-components";

interface PageWrapper{
    hidesidebar?: boolean
}

export const PageWrapper = styled.div<PageWrapper>`
    ${props => props.hidesidebar ? 'height: 600px;' : 'height: 100vh;'}
    ${props => props.hidesidebar ? 'overflow: auto;' : 'overflow: hidden;'}
    display: flex;
    background-color: #fff;
    padding: 20px;
    gap: 20px;	
`

interface ContentWrapper{
    hidesidebar?: boolean
}

export const ContentWrapper = styled.div<ContentWrapper>`
    width: 100%;
    ${props => props.hidesidebar ? '' : 'height: calc(100vh - 40px);'}
`

export const RightContentWrapper = styled.div`
`