import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #1270FC;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    
    &:hover {
        background-color:#3585fd;
        transition: background-color 0.2s ease-in-out;
    }
`