import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { HiOutlineUserCircle, HiOutlineUserGroup } from "react-icons/hi2";
import { IoHomeOutline } from "react-icons/io5";
import { LuCalendarDays, LuWallet } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/check.png";
import LogoutIcon from "../../assets/logout.svg";
import Settings from "../../assets/settings.svg";
import { getName, getProfessionalPhoto } from "../../service/token.service";
import { FaLaptopMedical, FaHandHoldingMedical } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { MenuButton } from "./MenuButton";
import {
  FooterWrapper,
  Icon,
  IconsWrapper,
  ImageUser,
  InfosUserWrapper,
  InfosWrapper,
  LogoWrapper,
  MenuItensWrapper,
  Role,
  SideMenuWrapper,
  TopWrapper,
  Username,
} from "./style";

export const SideMenu = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState<string>();

  const isActive = (route: string) => {
    return window.location.pathname === route;
  };

  const navigateTo = (route: string) => {
    navigate(route);
  };

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      const tokenData = jwtDecode(token) as any;
      setUserRole(tokenData.groups[0] || "");
    }
  }, []);

  const Logout = () => {
    window.location.href = "/login";
    localStorage.removeItem("bridges.token");
  };

  return (
    <SideMenuWrapper>
      <TopWrapper>
        <LogoWrapper src={Logo} alt="Logo" />
        <MenuItensWrapper>
          {userRole == "gestor" ||
          userRole == "profissional" ||
          userRole == "recepcionista" ||
          userRole == "atendimento" ? (
            <MenuButton
              isActive={isActive("/today")}
              onClick={() => navigate("/today")}
              title="Hoje"
              leftIcon={
                <IoHomeOutline color="var(--primary-icon-color)" size={20} />
              }
            />
          ) : (
            ""
          )}

          {userRole == "gestor" ||
          userRole == "profissional" ||
          userRole == "financeiro" ||
          userRole == "atendimento" ? (
            <MenuButton
              isActive={isActive("/")}
              onClick={() => navigate("/")}
              title="Agenda"
              leftIcon={
                <LuCalendarDays color="var(--primary-icon-color)" size={20} />
              }
            />
          ) : (
            ""
          )}

          {/* <MenuButton
            isActive={isActive("/dashboard")}
            onClick={() => navigate("/dashboard")}
            title="Carteira"
            leftIcon={
              <TbCashBanknote color="var(--primary-icon-color)" size={20} />
            }
          /> */}
          {userRole == "gestor" ||
          userRole == "recepcionista" ||
          userRole == "financeiro" ? (
            <MenuButton
              isActive={isActive("/wallet")}
              onClick={() => navigate("/wallet")}
              title="Carteira"
              leftIcon={
                <LuWallet color="var(--primary-icon-color)" size={20} />
              }
            />
          ) : (
            ""
          )}

          {userRole == "gestor" && (
            <MenuButton
              isActive={isActive("/professionals")}
              onClick={() => navigate("/professionals")}
              title="Profissionais"
              leftIcon={
                <HiOutlineUserGroup
                  color="var(--primary-icon-color)"
                  size={20}
                />
              }
            />
          )}
          {userRole == "gestor" ||
          userRole == "profissional" ||
          userRole == "atendimento" ? (
            <MenuButton
              isActive={isActive("/patients")}
              onClick={() => navigate("/patients")}
              title="Pacientes"
              leftIcon={
                <HiOutlineUserCircle
                  color="var(--primary-icon-color)"
                  size={20}
                />
              }
            />
          ) : (
            ""
          )}
          {userRole == "gestor" || userRole == "atendimento" ? (
            <MenuButton
              isActive={isActive("/postConsultation")}
              onClick={() => navigate("/postConsultation")}
              title="Pós Consulta"
              leftIcon={
                <FaHandHoldingMedical
                  color="var(--primary-icon-color)"
                  size={20}
                />
              }
            />
          ) : (
            ""
          )}
          {userRole == "gestor" || userRole == "atendimento" ? (
            <MenuButton
              isActive={isActive("/preConsultation")}
              onClick={() => navigate("/preConsultation")}
              title="Pré Consulta"
              leftIcon={
                <FaLaptopMedical color="var(--primary-icon-color)" size={20} />
              }
            />
          ) : (
            ""
          )}
          {userRole == "gestor" ? (
            <MenuButton
              isActive={isActive("/control")}
              onClick={() => navigate("/control")}
              title="Relatórios"
              leftIcon={
                <BiSolidReport color="var(--primary-icon-color)" size={20} />
              }
            />
          ) : (
            ""
          )}
          {/* <MenuButton
            isActive={isActive("/control")}
            onClick={() => navigate("/control")}
            title="Controle"
            leftIcon={
              <MdInsertChartOutlined
                color="var(--primary-icon-color)"
                size={20}
              />
            }
          /> */}
        </MenuItensWrapper>
      </TopWrapper>
      <FooterWrapper>
        <InfosWrapper>
          <ImageUser
            src={"https://api.bridges.conjosa.com.br" + getProfessionalPhoto()}
          />
          <InfosUserWrapper>
            <Username>{getName()}</Username>
            <Role>Bem vindo!</Role>
          </InfosUserWrapper>
          <IconsWrapper>
            <Icon src={Settings} />
            <Icon src={LogoutIcon} onClick={() => Logout()} />
          </IconsWrapper>
        </InfosWrapper>
      </FooterWrapper>
    </SideMenuWrapper>
  );
};

export default SideMenu;
